import { useState } from 'react';
import { Input, Space } from 'antd';
import { ApiUtils } from '../../utils/api.jsx';
import PATHS from '../../utils/constants/Paths.tsx';
import { useTranslation } from 'react-i18next';
import { Message, Button } from '../../utils/antd-components/index.tsx';

const ForgotPassword = () => {
  // Managing form states
  const [email, setEmail] = useState('');
  const [newPassword, setNewPassword] = useState('');
  const [verificationCode, setVerificationCode] = useState('');
  const { t } = useTranslation();
  const [errorMessage, setErrorMessage] = useState('');
  const [successMessage, setSuccessMessage] = useState('');
  const handleSend = async () => {
    console.log('Email:', email);
    try {
      const payload = { email };
      const URL = PATHS.AUTH.FORGOTPASSWORD;
      const response = await ApiUtils(URL, payload, 'POST');
      if (response.status !== 200) {
        setErrorMessage(response.message);
      } else {
        setSuccessMessage(t('verification-code-sent-successfully'));
      }
    } catch (error) {
      console.log(t('error'), error);
      setErrorMessage(t('failed-to-send-verification-code'));
    }
  };
  const handleResend = async () => {
    try {
      if (!email) {
        setErrorMessage(t('please-enter-your-email-address'));
        return;
      }
      const payload = { email };
      const URL = PATHS.AUTH.RESENDCODE;
      const response = await ApiUtils(URL, payload, 'POST');
      if (response.status !== 200) {
        setErrorMessage(response.message);
      } else {
        setSuccessMessage(t('verification-code-sent-successfully-0'));
      }
    } catch (error) {
      console.log(t('error-0'), error);
      setErrorMessage('Failed to send verification code');
    }
  };

  const handleSave = async () => {
    try {
      const payload = {
        email,
        verificationCode,
        newPassword,
      };
      console.log('Payload:', payload);
      const URL = PATHS.AUTH.CONFIRMPASSWORD;
      const response = await ApiUtils(URL, payload, 'POST');
      if (response.status !== 200) {
        setErrorMessage(response.message);
      } else {
        setSuccessMessage(t('password-reset-successful'));
      }
    } catch (error) {
      console.log(t('error'), error);
      setErrorMessage(t('failed-to-send-verification-code-0'));
    }
  };
  const onChange = (value) => {
    setVerificationCode(value);
  };
  const value = {
    onChange,
  };
  return (
    <div className="flex flex-col justify-center items-center h-screen font-poppins w-full p-5 gap-5">
      <div className="w-2/5 flex flex-col gap-5">
        <div className="flex flex-row justify-center items-center text-2xl font-light text-[#0f2ec4]">
          {t('forgot-password')}
        </div>

        <div className="flex text-base font-normal gap-3 flex-col">
          {t('enter-the-registered-email-address-to-reset-your-password')}
          <Space.Compact className="w-full">
            <Input
              type="email"
              placeholder={t('email-1')}
              value={email}
              onChange={(e) => setEmail(e.target.value)}
              style={{ borderColor: '#0f2ec4' }}
            />
            <Button
              type="text"
              className="text-white font-semibold bg-[#0f2ec4]"
              onClick={handleSend}
            >
              {t('send')}
            </Button>
          </Space.Compact>
          <div
            className="flex text-xs items-center font-normal cursor-pointer flex-col hover:text-blue-500 hover:bo hover:drop-shadow-glow text-[#0f2ec4]"
            onClick={handleResend}
          >
            {t('did-not-receive-the-code-resend-verification-code-0')}
          </div>
        </div>

        <div className="flex text-base font-normal gap-3 flex-col">
          {t('enter-the-confirmation-code-sent-to-your-email')}
          <Input.OTP
            placeholder="Verification Code"
            {...value}
            style={{ borderColor: '#0f2ec4' }}
          />
        </div>

        <div className="flex text-base font-normal gap-3 flex-col">
          {t('enter-a-new-password')}
          <Space.Compact className="w-full">
            <Input.Password
              placeholder={t('new-password')}
              value={newPassword}
              onChange={(e) => setNewPassword(e.target.value)}
              style={{ borderColor: '#0f2ec4' }}
            />
            <Button
              type="text"
              className="text-white font-semibold bg-[#0f2ec4]"
              onClick={handleSave}
            >
              {t('save')}
            </Button>
          </Space.Compact>
        </div>
      </div>
      {errorMessage && (
        <Message className="mt-5" type="error" successMessage={errorMessage} />
      )}
      {successMessage && (
        <Message successMessage={successMessage} type="success" />
      )}
    </div>
  );
};

export default ForgotPassword;
