import { useState } from 'react';
import Header from '../layouts/Header';
import UserSidebar from '../layouts/UserSidebar';
import { Card, Input, Button, Form, Row, Col } from 'antd';
import { EditOutlined } from '@ant-design/icons';
import useAuthUser from 'react-auth-kit/hooks/useAuthUser';
import { ApiUtils } from '../utils/api';
import PATHS from '../utils/constants/Paths';
import METHOD_TYPES from '../utils/constants/MethodTypes';
import { Message } from '../utils/antd-components';
import { useTranslation } from 'react-i18next';
import Footer from '../layouts/Footer';
import loadingIcon from '.././assets/lottie-json/logo Animation.json';
import Lottie from 'lottie-react';

const UserProfile = () => {
  const authUser = useAuthUser();
  const userDetails = authUser.userDetails;
  const { t } = useTranslation();

  console.log('userDetails', userDetails);

  const [form] = Form.useForm();
  const [isEditing, setIsEditing] = useState(false);
  const [publishError, setPublishError] = useState(null);
  const [successMessage, setsuccessMessage] = useState(null);
  const [isLoading, setIsLoading] = useState(false);

  const onFinish = async (e) => {
    setIsLoading(true);
    console.log('Form Values:', e);
    try {
      const payload = { ...e };
      console.log('STAR+++++++==', payload);
      const URL = `${PATHS.USER.UPDATE}/${userDetails?._id}`;
      const data = await ApiUtils(URL, payload, METHOD_TYPES.PUT);
      if (data.status !== 200) {
        setPublishError(data.message);
        setIsLoading(false);
        return;
      }

      if (data.status === 200) {
        setsuccessMessage(data.response.messsage);
        setPublishError(null);
        setIsLoading(false);
      }
    } catch (error) {
      setPublishError(t('something-went-wrong-0'));
      setIsLoading(false);
    }
    setIsEditing(false);
  };

  const toggleEditMode = () => {
    setIsEditing(!isEditing);
  };

  return (
    <div className="w-full h-[100vh] ">
      <Header />
      <div className="flex bg-[#b7d9ff]  justify-center items-center w-full h-[90%]  p-8   gap-8">
        <UserSidebar />
        <Card
          style={{ width: '100%', height: '100%', borderRadius: '10px' }}
          className="shadow-lg p-6 "
        >
          <div className="flex justify-between items-center mb-6">
            <h2 className="text-[#0f2ec4] text-2xl font-semibold">
              {t('your-profile-info')}
            </h2>
            <Button
              type="link"
              icon={<EditOutlined />}
              className="text-[#0f2ec4]"
              style={{ fontSize: '16px' }}
              onClick={toggleEditMode}
            >
              {isEditing ? t('cancel') : t('edit-1')}
            </Button>
          </div>

          <Form
            form={form}
            layout="vertical"
            onFinish={onFinish}
            initialValues={{
              name: userDetails?.name || '',
              organization: userDetails?.organization || '',
              email: userDetails?.email || '',
              ph_no: userDetails?.ph_no || '',
            }}
            className="flex flex-col gap-4"
          >
            <Row gutter={16}>
              <Col span={8}>
                <Form.Item
                  name="name"
                  label={t('Name')}
                  rules={[
                    { required: true, message: t('please-enter-your-name') },
                    {
                      pattern: /^[a-zA-Z\s]*$/,
                      message: t('name-cannot-contain-numbers'),
                    },
                    {
                      min: 6,
                      message: t('username-must-be-at-least-6-characters-long'),
                    },
                  ]}
                >
                  <Input
                    placeholder={t('Name')}
                    disabled={!isEditing}
                    style={{ borderColor: '#0f2ec4' }}
                  />
                </Form.Item>
              </Col>

              <Col span={8}>
                <Form.Item
                  name="email"
                  label={t('email')}
                  rules={[
                    { required: true, message: t('please-enter-your-email') },
                    { type: 'email', message: t('please-enter-a-valid-email') },
                  ]}
                >
                  <Input
                    placeholder={t('email')}
                    disabled={!isEditing}
                    style={{ borderColor: '#0f2ec4' }}
                  />
                </Form.Item>
              </Col>

              <Col span={8}>
                <Form.Item name="organization" label={t('organization')}>
                  <Input
                    placeholder={t('organization')}
                    disabled={!isEditing}
                    style={{ borderColor: '#0f2ec4' }}
                  />
                </Form.Item>
              </Col>
            </Row>

            <Row gutter={16}>
              <Col span={8}>
                <Form.Item
                  name="ph_no"
                  label={t('phone-number')}
                  rules={[
                    {
                      type: 'number',
                      message: t('please-enter-a-valid-phone-number'),
                    },
                  ]}
                >
                  <Input
                    placeholder={t('phone-number')}
                    disabled={!isEditing}
                    style={{ borderColor: '#0f2ec4' }}
                  />
                </Form.Item>
              </Col>
            </Row>

            {isEditing && (
              <div className="flex justify-center mt-4">
                <Button
                  type="primary"
                  htmlType="submit"
                  style={{
                    background:
                      'linear-gradient(90deg, #0f2ec4 0%, #0f2ec4 100%)',
                    borderRadius: '25px',
                    width: '150px',
                  }}
                >
                  {t('save')}
                </Button>
              </div>
            )}
          </Form>
        </Card>
      </div>
      {publishError && <Message publishError={publishError} type="error" />}
      {successMessage && (
        <Message successMessage={successMessage} type="success" />
      )}
      {isLoading && (
        <div className="absolute w-screen h-screen flex justify-center items-center inset-0 bg-white bg-opacity-50">
          <Lottie animationData={loadingIcon} loop={true} className="h-40" />
        </div>
      )}
      <Footer />
    </div>
  );
};

export default UserProfile;
