import { Layout, Typography } from 'antd';
// import {
//   BsFacebook,
//   BsInstagram,
//   BsTwitter,
//   BsGithub,
//   BsDribbble,
// } from 'react-icons/bs';
import { GiDiamondsSmile } from 'react-icons/gi';
import { useTranslation } from 'react-i18next';
// import Netkathir from '../assets/images/Netkathir.png';
import { useState } from 'react';
import { Message } from '../utils/antd-components/index.tsx';
import routesnkt from '../routes/routes.json';

const { Footer } = Layout;
const { Link } = Typography;

export default function FooterCom() {
  const { t } = useTranslation();
  const [clickCount, setClickCount] = useState(0);

  const handleTeamClick = () => {
    setClickCount((prevCount) => prevCount + 1);
  };

  return (
    <Footer className="bg-white shadow-[0_-4px_6px_rgba(0,0,0,0.1)]  ">
      <div className="w-full max-w-7xl mx-auto">
        <div className="w-full justify-between flex"></div>
        {/* <div className="w-full sm:flex sm:items-center sm:justify-between">
          <div className="gap-4">
            <Link
              href="https://netkathir.com"
              target="_blank"
              className="self-center whitespace-nowrap text-lg sm:text-xl font-semibold dark:text-white"
            >
              <img
                src={Netkathir}
                alt="user"
                className="block mx-auto w-1/2 mb-5"
              />
            </Link>
            <Typography.Text>
              <Link href="https://netkathir.com" target="_blank">
                {t('Netkathir Technologies_Recycle')}
              </Link>{' '}
              | &copy;{new Date().getFullYear()}
            </Typography.Text>
          </div>

          <p className="text-sm mb-5 mt-5 py-4 text-start text-white-500">
            <b>{t('address')}</b>
            <br />
            {t('Address')}
            <br />
            {t('Address1')}
            <br />
            {t('Address2')}
            <br />
          </p>
          <p className="text-sm mb-5 mt-5 py-4 text-start text-white-500">
            <b> {t('Email')}</b> admin@netkathir.com
            <br />
            <b>{t('Phone')}</b> 0413-2233944
          </p>
          <div className="flex gap-6 sm:mt-0 mt-4 sm:justify-center">
            <Link href="https://www.facebook.com/Netkathir/" target="_blank">
              <BsFacebook />
            </Link>
            <Link href="https://www.instagram.com/netkathir/" target="_blank">
              <BsInstagram />
            </Link>
            <Link href="https://www.instagram.com/netkathir/" target="_blank">
              <BsTwitter />
            </Link>
            <Link href="https://www.instagram.com/netkathir/" target="_blank">
              <BsGithub />
            </Link>
            <Link href="https://www.instagram.com/netkathir/" target="_blank">
              <BsDribbble />
            </Link>
          </div>
        </div> */}
        <div className="w-full sm:flex sm:items-center sm:justify-center">
          <div className="mr-5">
            <Typography.Text>
              <Link href={routesnkt.Nkt} target="_blank">
                Post Ads
              </Link>{' '}
              | &copy;{new Date().getFullYear()}
            </Typography.Text>
          </div>
          <>
            <span className="mr-1"> Made with </span>
            <span onClick={handleTeamClick}>React By NKT Team </span>
          </>
        </div>
      </div>

      {clickCount === 4 && (
        <Message
          successMessage={
            <>
              {t('built-by-ram-and-sundar')}
              <GiDiamondsSmile color="red" />
            </>
          }
          className="w-full"
          type="success"
          position="bottom"
          icon={<GiDiamondsSmile color="red" />}
        />
      )}
    </Footer>
  );
}
