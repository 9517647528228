import { Spinner, Button } from '../../utils/antd-components/index.tsx';
import { EyeInvisibleOutlined, EyeTwoTone } from '@ant-design/icons';
import { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';

import {
  signupSuccess,
  signInStart,
  signInStop,
  signInFailure,
} from '../../redux/user/userSlice.js';
import { ApiUtils } from '../../utils/api.jsx';
//import useAuthUser from 'react-auth-kit/hooks/useAuthUser';
import PATHS from '../../utils/constants/Paths.tsx';
import METHOD_TYPES from '../../utils/constants/MethodTypes.tsx';
import { message, Typography, Input, Space } from 'antd';
import { useTranslation } from 'react-i18next';

export default function SignIn() {
  // const authUser = useAuthUser();
  // const userEmail = authUser?.email;
  const { loading, signup } = useSelector((state) => state.user);
  // console.log('signup', signup);
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { t } = useTranslation();

  const [passwordVisible, setPasswordVisible] = useState(false);
  const [code, setCode] = useState('');
  const onChange = (text) => {
    setCode(text);
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    console.log('Code:', code);
    if (!code) {
      return dispatch(signInFailure(t('please-fill-all-the-fields')));
    }
    if (!signup?.username) {
      return dispatch(signInFailure(t('email-not-found')));
    }
    try {
      dispatch(signInStart());
      let payload = {
        confirmationCode: code,
        username: signup?.username,
      };

      console.log('payload:', payload);
      const URL = PATHS.AUTH.VERIFYEMAIL;
      const data = await ApiUtils(URL, payload, METHOD_TYPES.POST);
      if (data.status !== 200) {
        message.error(data.message);
        dispatch(signInFailure(data.message));
      }

      if (data.status === 200) {
        message.success({
          ['verified-successfully']: t('verified-successfully'),
        });
        dispatch(signupSuccess(null));
        navigate('/sign-in');
      }
    } catch (error) {
      dispatch(signInFailure(error.message));
    }
  };
  const resendCode = async () => {
    try {
      dispatch(signInStart());
      let payload = {
        email: signup?.email,
      };
      console.log('payload:', payload);
      const URL = PATHS.AUTH.RESENDCODE;
      const data = await ApiUtils(URL, payload, METHOD_TYPES.POST);
      console.log(data);
      if (data.status !== 200) {
        dispatch(signInFailure(data.message));
      }
      if (data.status === 200) {
        dispatch(signInStop());
      }
    } catch (error) {
      dispatch(signInFailure(error.message));
    }
  };

  const sharedProps = {
    onChange,
  };
  return (
    <div className="flex justify-center h-screen flex-col gap-4 items-center ">
      <Typography className="font-poppins text-xl ">
        {t('a-verification-code-has-been-sent-to-your-email-0')}
      </Typography>
      <Space direction="horizontal">
        <Input.OTP
          {...sharedProps}
          mask={passwordVisible ? false : '🔒'}
          style={{ borderColor: '#0f2ec4' }}
        />
        <div
          className="w-20 px-5"
          onClick={() => setPasswordVisible((prevState) => !prevState)}
        >
          {passwordVisible ? <EyeTwoTone /> : <EyeInvisibleOutlined />}
        </div>
      </Space>
      <Button onClick={handleSubmit} disabled={loading}>
        {loading ? (
          <>
            <Spinner size="large" />
            <span className="pl-3">{t('verifying')}</span>
          </>
        ) : (
          <p>{t('verify-0')}</p>
        )}
      </Button>
      <Typography variant="h1" className="font-poppins">
        {t('enter-the-6-digit-code-sent-to-your-email-0')}
      </Typography>
      <div
        className="flex text-xs items-center font-normal cursor-pointer flex-col hover:text-blue-500 hover:bo hover:drop-shadow-glow text-[#0f2ec4]"
        onClick={resendCode}
      >
        {t('did-not-receive-the-code-resend-verification-code')}
      </div>
    </div>
  );
}
