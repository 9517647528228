export const STATUSLIST = (t) => [
  { _id: 'all', name: t('all') },
  { _id: '1', name: t('active') },
  { _id: '2', name: t('inActive') },
  { _id: '3', name: t('deleted') },
];

export const ATTENDENCEOPTION = (t) => [
  { value: '1', name: t('Present') },
  { value: '2', name: t('Absent') },
];

export const SESSION = [
  { _id: '1', name: 'Forenoon' },
  { _id: '2', name: 'Afternoon' },
  { _id: '3', name: 'Full Day' },
];

export const CATEGORYLIST =
  [
    {
      value: '1',
      label: 'Sale'
    },
    {
      value: '2',
      label: 'Rent'
    },
    {
      value: '3',
      label: 'Job'
    }
  ];

export const LANGUAGELIST = [
  { _id: '1', name: 'English', value: 'en-US' },
  { _id: '5', name: 'Tamil', value: 'ta' }, // Standard language for Singapore
];

export const LEAVEOPTION = [
  {
    value: '1',
    name: 'Casual leave',
  },
  {
    value: '2',
    name: 'Permission',
  },
  {
    value: '3',
    name: 'Sick Leave',
  },
  {
    value: '4',
    name: 'Maternity Leave',
  },
  {
    value: '5',
    name: 'Paternity Leave',
  },
  {
    value: '6',
    name: 'Marriage Leave',
  },
  {
    value: '7',
    name: 'Absent',
  },
];
