import { useEffect, useState } from 'react';
import { Layout, Row, Col } from 'antd';
import CustomHeader from '../layouts/Header';
import ContentCard from './ContentCard';
import Lottie from 'lottie-react';
import loadingIcon from '.././assets/lottie-json/logo Animation.json';
import METHOD_TYPES from '../utils/constants/MethodTypes';
import PATHS from '../utils/constants/Paths';
import { ApiUtils } from '../utils/api';
import Footer from '../layouts/Footer';

const { Header, Content } = Layout;

const SalesPage = () => {
  const [ads, setAds] = useState([]);
  const [isLoading, setIsLoading] = useState(false);

  useEffect(() => {
    setIsLoading(true);
    const fetchAds = async () => {
      try {
        const URL = `${PATHS.ADS.GET}?category=1&status=1`;
        const response = await ApiUtils(URL, METHOD_TYPES.GET);
        if (response.status === 200) {
          setAds(response.data.ads);
          setIsLoading(false);
        }
      } catch (error) {
        console.error('Error fetching data:', error);
        setIsLoading(false);
      }
    };

    fetchAds();
  }, []);

  // Control body overflow during loading
  useEffect(() => {
    if (isLoading) {
      document.body.style.overflow = 'hidden';
    } else {
      document.body.style.overflow = 'auto';
    }
    return () => {
      document.body.style.overflow = 'auto';
    };
  }, [isLoading]);

  // const breakpoint = {
  //   default: 4,
  //   1100: 3,
  //   700: 2,
  // };

  return (
    <Layout className="w-full  bg-[#b7d9ff] relative">
      <Header className="z-20 p-0 sticky top-0 bg-[#ffffff]">
        <CustomHeader />
      </Header>
      <Content
        className={`h-full  pt-10 px-10 transition-opacity duration-300 ${isLoading ? 'blur-sm' : ''
          }`}
      >
        <Row
          gutter={[30, 30]}
          justify="start"
          className="py-10 flex justify-center items-center"
        >
          {ads.map((note) => (
            <Col
              key={note._id}
              xs={24}
              sm={18}
              md={12}
              lg={8}
              xl={6}
              xxl={6}
              className="flex justify-center items-center gap-3"
            >
              <ContentCard note={note} />
            </Col>
          ))}
        </Row>
      </Content>

      {isLoading && (
        <div className="absolute w-screen h-screen flex justify-center items-center inset-0 bg-white bg-opacity-50">
          <Lottie animationData={loadingIcon} loop={true} className="h-40" />
        </div>
      )}
      <Footer />
    </Layout>
  );
};

export default SalesPage;
