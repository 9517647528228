import { useState } from 'react';
import ReactQuill from 'react-quill';
import 'react-quill/dist/quill.snow.css';
import {
  Carousel,
  Button,
  Input,
  Select,
  Typography,
  Modal,
  Radio,
  Form,
  message,
  Card,
} from 'antd';
import { CloseOutlined, RocketOutlined, EyeOutlined } from '@ant-design/icons';
import logoIcon from '../assets/image/icon.png';
import routesnkt from '../routes/routes.json';
import { useNavigate } from 'react-router-dom';
import Lottie from 'lottie-react';
import animation from '../assets/lottie-json/PostAds.json';
import PATHS from '../utils/constants/Paths';
import { ApiUtils } from '../utils/api';
import ImageUpload from '../components/ImageUpload';
import { CATEGORYLIST } from '../utils/constants/index';
import METHOD_TYPES from '../utils/constants/MethodTypes';
import useAuthUser from 'react-auth-kit/hooks/useAuthUser';
import { useTranslation } from 'react-i18next';

const { Title, Text } = Typography;
const { Option } = Select;

const PostAdsForm = () => {
  const authUser = useAuthUser();
  const userDetails = authUser.userDetails;
  const navigate = useNavigate();
  const [open, setOpen] = useState(false);
  const [adType, setAdType] = useState('');
  const [form] = Form.useForm();
  const { t } = useTranslation();
  const [error, setError] = useState('');

  const handleOpen = () => setOpen(!open);

  const handleImageUpload = (uploadedImage) => {
    form.setFieldsValue({
      photo: uploadedImage,
    });
  };

  const handleSubmit = async () => {
    try {
      const values = await form.validateFields();
      // const plainText = values.descp?.replace(/<\/?[^>]+(>|$)/g, '') || '';
      // if (plainText.length > maxChars) {
      //   setError(
      //     t("content-exceeds-the-maximum-character-limit-of-100-characters")
      //   );
      //   return;
      // }

      const payload = {
        title: values.title,
        category: values.category,
        descp: values.descp,
        type: adType,
        status: 2,
        username: userDetails.username,
        photo: values.photo,
      };

      const response = await ApiUtils(
        PATHS.ADS.TEXTPOST,
        payload,
        METHOD_TYPES.POST,
      );

      if (response.status === 200) {
        message.success('Ad posted successfully!');
        setError(null);
        navigate(routesnkt.home);
      } else {
        message.error(t('failed-to-post-the-ad'));
      }
    } catch (error) {
      message.error(t('there-was-an-error-submitting-the-ad'));
      console.error('Error:', error);
    }
  };

  return (
    <div className="w-screen h-screen">
      <div className="flex h-full flex-col lg:flex-row">
        <div className="lg:w-2/5 h-[40%] lg:h-full lg:rounded-l-none lg:rounded-r-[150px] rounded-b-[120px] flex flex-col items-center justify-center overflow-hidden bg-[#0f2ec4]">
          <Lottie
            animationData={animation}
            loop={false}
            className="flex h-3/6"
          />
          <div className="w-4/5 lg:w-full flex-col justify-between items-center">
            <Carousel
              autoplay
              className="p-8 pl-32 flex flex-row justify-center items-center"
            >
              {[
                t('create-your-ad-and-reach-the-right-audience'),
                t('advertise-with-ease-and-get-your-message-out-there'),
                t('post-your-ad-and-connect-with-potential-buyers'),
                t('let-your-ad-shine-by-start-posting-now'),
                t('promote-sell-or-rent-post-your-ad-in-minutes'),
              ].map((text, index) => (
                <div key={index}>
                  <Text className="text-white text-center font-medium font-poppins text-lg">
                    {text}
                  </Text>
                </div>
              ))}
            </Carousel>
          </div>
        </div>

        <div className="flex flex-col lg:px-24 px-10 w-full h-full lg:w-3/5 overflow-y-auto">
          <div className="flex items-center justify-between py-5">
            <div className="flex flex-row">
              <img src={logoIcon} alt="The ICON" className="h-14" />
              <Title level={5} className="pl-4 pt-2 font-poppins">
                {t('the')}
                <br />
                {t('ads')}
              </Title>
            </div>
            <Button
              icon={<CloseOutlined />}
              type="text"
              onClick={() => navigate('/')}
            />
          </div>

          <Form form={form} layout="vertical">
            <Title level={6} className="font-poppins flex mb-0">
              {t('post-your-ad-now')}
            </Title>
            <Text className="text-base font-poppins">
              {t(
                'fill-out-the-form-below-to-create-your-ad-it-only-takes-a-few-steps-to-reach-thousands',
              )}
            </Text>

            <Form.Item
              name="category"
              label={t('category-0')}
              rules={[
                { required: true, message: t('please-select-a-category') },
              ]}
              className="pt-4"
            >
              <Select
                placeholder={t('please-select-a-category')}
                style={{ borderColor: '#0f2ec4' }}
              >
                {CATEGORYLIST.map((category) => (
                  <Option key={category.value} value={category.value}>
                    {category.label}
                  </Option>
                ))}
              </Select>
            </Form.Item>

            <Form.Item name="type" label={t('type-0')}>
              <Radio.Group
                onChange={(e) => setAdType(e.target.value)}
                value={adType}
                style={{ borderColor: '#0f2ec4' }}
              >
                <Radio value={1}>{t('image-0')}</Radio>
                <Radio value={2}>{t('text')}</Radio>
              </Radio.Group>
            </Form.Item>

            {adType === 2 && (
              <>
                <Form.Item
                  name="title"
                  label={t('title-0')}
                  rules={[
                    { required: true, message: t('please-enter-a-title') },
                  ]}
                >
                  <Input placeholder={t('enter-your-title')} />
                </Form.Item>

                <Form.Item name="descp" rules={[{ required: true }]}>
                  <ReactQuill
                    theme="snow"
                    placeholder={t('writesomething')}
                    className="h-40 mb-12"
                    style={{ borderColor: '#0f2ec4' }}
                  />
                </Form.Item>

                {error && (
                  <div className="text-red-500 text-sm font-poppins">
                    {error}
                  </div>
                )}
              </>
            )}

            {adType === 1 && (
              <Form.Item
                name="photo"
                label="Upload Image"
                className="max-w-full "
              >
                <ImageUpload setFormData={handleImageUpload} />
              </Form.Item>
            )}

            <div className="py-12 flex gap-20 justify-center place-items-center">
              <Button
                type="primary"
                icon={<EyeOutlined />}
                onClick={handleOpen}
                className="flex items-center gap-3 text-white font-poppins font-medium text-sm rounded-full bg-gradient-to-r from-[#0f2ec4] to-[#0f2ec4] py-2 px-4 normal-case transition hover:scale-105"
              >
                {t('preview')}
              </Button>

              <Modal
                title="Preview"
                open={open}
                onOk={handleOpen}
                onCancel={handleOpen}
              >
                <Card
                  title={form.getFieldValue('title')}
                  extra={form.getFieldValue('category')}
                >
                  <p>
                    {adType === 2 ? (
                      form.getFieldValue('descp')
                    ) : form.getFieldValue('photo') ? (
                      <img
                        src={form.getFieldValue('photo')}
                        alt="Preview"
                        style={{ maxWidth: '100%' }}
                      />
                    ) : (
                      t('nothing-to-preview')
                    )}
                  </p>
                </Card>
              </Modal>

              <Button
                type="primary"
                icon={<RocketOutlined />}
                onClick={handleSubmit}
                className="flex items-center gap-3 text-white font-poppins font-medium text-sm rounded-full bg-gradient-to-r from-[#0f2ec4] to-[#0f2ec4] py-2 px-4 normal-case transition hover:scale-105"
              >
                {t('publish')}
              </Button>
            </div>
          </Form>
        </div>
      </div>
    </div>
  );
};

export default PostAdsForm;
