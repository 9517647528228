const PATHS = Object.freeze({
  AUTH: {
    SIGNUP: '/api/v1/auth/sign-up',
    SIGNIN: '/api/v1/auth/sign-in',
    FORGOTPASSWORD: '/api/v1/auth/forgot-password',
    CHANGEPASSWORD: '/api/v1/auth/change-password',
    CONFIRMPASSWORD: '/api/v1/auth/confirm-password',
    RESENDCODE: '/api/v1/auth/resend-confirm-code',
    VERIFYEMAIL: '/api/v1/auth/verify-email',
  },

  ADS: {
    GET: '/api/v1/admin/get-ads',
    TEXTPOST: '/api/v1/admin/add-ads',
    IMAGE: '/api/v1/file-upload',
    UPDATE: '/api/v1/admin/update-ads',
    DELETE: '/api/v1/admin/delete-ads',
  },
  USER: {
    ADD: 'api/v1/add-users',
    UPDATE: '/api/v1/admin/update-users',
  },
});
export default PATHS;
