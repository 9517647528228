import React, { useState, useMemo } from 'react';
import { Image, Badge } from 'antd';
import DOMPurify from 'dompurify';
import "../tailwind.css";

// Define types for the note prop
type Note = {
  title?: string;
  descp?: string;
  category: number;
  photo?: string;
};

const CATEGORIES: Record<number, string> = {
  1: 'Sales',
  2: 'Rent',
  3: 'Jobs',
};

interface ContentCardProps {
  note: Note;
}

const ContentCard: React.FC<ContentCardProps> = ({ note }) => {
  const [isExpanded, setIsExpanded] = useState<boolean>(false);

  const { title, descp, category, photo } = note;

  const categoryText = useMemo(
    () => CATEGORIES[category] || 'Unknown',
    [category],
  );

  const toggleExpand = () => setIsExpanded((prev) => !prev);

  const sanitizedHtml = useMemo(() => DOMPurify.sanitize(descp || ''), [descp]);

  return (
    <div className="w-[350px] min-h-[250px] rounded-lg transition hover:scale-105 bg-white shadow-md relative">
      <Badge.Ribbon text={categoryText} color="blue" >
        {photo ? (
          <div className="relative w-full h-full  flex justify-center items-center ">
            <Image
              alt="cover"
              src={photo}
              className="w-full object-cover cursor-pointer rounded-lg"
              style={{height: '390px'}}
            />
          </div>
        ) : (
          <div className="p-4 flex flex-col justify-start h-full">
            {title && (
              <div className="description-column">
                <h2 className="text-xl font-bold mb-2 pt-4 mr-2">{title}</h2>
              </div>
            )}
            {descp && (
              <div>
                <div
                  className={`description ${isExpanded ? 'h-auto description-column' : 'h-[250px] overflow-hidden'}`}
                  dangerouslySetInnerHTML={{
                    __html: isExpanded
                      ? sanitizedHtml
                      : sanitizedHtml.substring(0, 1000) + '...',
                  }}
                />
                <button
                  className="text-blue-500 cursor-pointer mt-2"
                  onClick={toggleExpand}
                >
                  {isExpanded ? 'Show Less' : 'Show More'}
                </button>
              </div>
            )}
          </div>
        )}
      </Badge.Ribbon>
    </div>
  );
};

export default React.memo(ContentCard);
