import { useState, useEffect } from 'react';
import { Card, Typography, Menu, Badge } from 'antd';
import {
  UserOutlined,
  InboxOutlined,
  PoweroffOutlined,
} from '@ant-design/icons';
import { useDispatch } from 'react-redux';
import useSignOut from 'react-auth-kit/hooks/useSignOut';
import { useNavigate, useLocation } from 'react-router-dom';
import useAuthUser from 'react-auth-kit/hooks/useAuthUser';
import { signoutSuccess } from '../redux/user/userSlice.js';
import { useTranslation } from 'react-i18next';

const { Title } = Typography;

const UserSidebar = () => {
  const authUser = useAuthUser();
  const userDetails = authUser.userDetails;

  const { t } = useTranslation();
  const signOut = useSignOut();
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const location = useLocation();
  console.log('userdetails>>>>', userDetails);
  // Automatically set the selected key based on the current URL
  const [selected, setSelected] = useState('1');

  useEffect(() => {
    switch (location.pathname) {
      case '/profile':
        setSelected('1');
        break;
      case '/user-ads':
        setSelected('2');
        break;
      case '/all-ads':
        setSelected('3');
        break;
      default:
        setSelected('1');
    }
  }, [location.pathname]);

  const handleSignout = async () => {
    try {
      signOut();
      dispatch(signoutSuccess());
      navigate('/');
    } catch (error) {
      console.log('signouterr=====', error);
    }
  };

  // Define the menu items array
  const menuItems = [
    {
      key: '1',
      icon: <UserOutlined />,
      label: <a href="/profile">{t('profile-1')}</a>,
    },
    {
      key: '2',
      icon: <InboxOutlined />,
      label: (
        <>
          <a href="/user-ads">{t('your-ads')}</a>
          {/* <Badge count={14} style={{ marginLeft: '8px' }} /> */}
        </>
      ),
    },
    {
      key: '3',
      icon: <PoweroffOutlined />,
      label: t('log-out'),
      onClick: handleSignout,
    },
  ];
  const adminMenu = [
    {
      key: '1',
      icon: <UserOutlined />,
      label: <a href="/profile">{t('profile-1')}</a>,
    },
    {
      key: '2',
      icon: <InboxOutlined />,
      label: (
        <>
          <a href="/user-ads">{t('your-ads')}</a>
          {/* <Badge count={14} style={{ marginLeft: '8px' }} /> */}
        </>
      ),
    },
    {
      key: '3',
      icon: <InboxOutlined />,
      label: (
        <>
          <a href="/all-ads">{t('All Ads')}</a>
        </>
      ),
    },
    {
      key: '4',
      icon: <PoweroffOutlined />,
      label: t('log-out'),
      onClick: handleSignout,
    },
  ];
  return (
    <Card
      style={{
        height: '100%',
        width: '100%',
        maxWidth: '20rem',
        padding: '16px',
        borderRadius: '10px',
      }}
      className="shadow-lg"
    >
      <div style={{ padding: '8px' }}>
        <Title level={4} style={{ color: '#0f2ec4', fontFamily: 'Poppins' }}>
          {t('your-account')}
        </Title>
      </div>
      <Menu
        mode="inline"
        selectedKeys={[selected]} // Selected key from URL
        items={userDetails?.roleId?.name === 'admin' ? adminMenu : menuItems} // Use the items prop instead of children
        style={{ height: '100%', borderRight: 0 }}
      >
        <Menu.Item key="1" icon={<UserOutlined />}>
          <a href="/profile">Profile</a>
        </Menu.Item>
        <Menu.Item key="2" icon={<InboxOutlined />}>
          <a href="/user-ads">
            {t('your-ads')}
            <Badge count={14} style={{ marginLeft: '8px' }} />
          </a>
        </Menu.Item>
        <Menu.Item key="3" icon={<PoweroffOutlined />} onClick={handleSignout}>
          {t('log-out')}
        </Menu.Item>
      </Menu>
    </Card>
  );
};

export default UserSidebar;
