import { useState, useCallback, useEffect } from 'react';
import { Layout, Typography, Button, Drawer, Menu, Avatar, Select } from 'antd';
import {
  ShoppingOutlined,
  HomeOutlined,
  ContactsOutlined,
  MenuOutlined,
  PlusOutlined,
} from '@ant-design/icons';
import logoIcon from '.././assets/image/icon.png';
import { GoBriefcase } from 'react-icons/go';
const { Header: AntHeader } = Layout;
const { Title } = Typography;
import routesnkt from '../routes/routes.json';
import { Link, useNavigate } from 'react-router-dom';
import { useLanguageContext } from '../utils/context/LanguageContext.tsx';
import { useTranslation } from 'react-i18next';

const Header = () => {
  const [isMobileMenuOpen, setIsMobileMenuOpen] = useState(false);
  const navigate = useNavigate();
  const { t } = useTranslation();
  const { LANGUAGELIST, onClickLanguageChange, i18n } = useLanguageContext();
  const { Option } = Select;

  const [selectedLanguage, setSelectedLanguage] = useState(i18n.language);

  useEffect(() => {
    setSelectedLanguage(i18n.language);
  }, [i18n.language]);

  const handleLanguageChange = (e) => {
    console.log('handleLanguageChange', e);
    setSelectedLanguage(e);
    onClickLanguageChange(e);
  };

  const toggleMobileMenu = () => {
    setIsMobileMenuOpen(!isMobileMenuOpen);
  };

  const getCurrentMenuKey = useCallback(() => {
    switch (location.pathname) {
      case '/sales':
        return '1';
      case '/rents':
        return '2';
      case '/jobs':
        return '3';
      case '/contact':
        return '4';
      default:
        return ' ';
    }
  }, [location.pathname]);

  const [selectedKey, setSelectedKey] = useState(getCurrentMenuKey());

  useEffect(() => {
    setSelectedKey(getCurrentMenuKey());
  }, [getCurrentMenuKey]);

  const handleMenuClick = ({ key }) => {
    setSelectedKey(key);
    switch (key) {
      case '1':
        navigate('/sales');
        break;
      case '2':
        navigate('/rents');
        break;
      case '3':
        navigate('/jobs');
        break;
      case '4':
        navigate('/contact');
        break;
      default:
        break;
    }
  };

  const menuItems = [
    { key: '1', icon: <ShoppingOutlined />, label: t('sales') },
    { key: '2', icon: <HomeOutlined />, label: t('rents') },
    { key: '3', icon: <GoBriefcase />, label: t('jobs') },
    { key: '4', icon: <ContactsOutlined />, label: t('contact-us') },
  ];

  return (
    <AntHeader className="bg-white shadow-md h-20 flex items-center px-12">
      <div className="flex items-center justify-between w-full">
        <div
          className="flex items-center gap-3 cursor-pointer"
          onClick={() => navigate('/')}
        >
          <img src={logoIcon} alt="The ICON" className="h-12" />
          <Title level={5} className="font-poppins text-black m-0">
            {t('the')} <br />
            {t('ads')}
          </Title>
        </div>

        <div className="hidden lg:block flex-grow">
          <Menu
            mode="horizontal"
            selectedKeys={[selectedKey]}
            onClick={handleMenuClick}
            className="bg-transparent border-none font-poppins font-medium justify-center  "
            items={menuItems}
          />
        </div>

        <div className="hidden lg:flex items-center gap-3">
          <Select
            required={false}
            onChange={handleLanguageChange}
            value={selectedLanguage}
            placeholder={t('select-0')}
          >
            {LANGUAGELIST &&
              Object.keys(LANGUAGELIST).map((key) => (
                <Option key={key} value={key}>
                  {LANGUAGELIST[key].nativeName}
                </Option>
              ))}
          </Select>
          <Link to={routesnkt.postads}>
            <Button
              type="primary"
              icon={<PlusOutlined />}
              className="flex items-center gap-2 rounded-full bg-gradient-to-r from-[#0f2ec4] to-[#0f2ec4]"
            >
              {t('post-ads')}
            </Button>
          </Link>
          <Avatar
            src="https://i.pravatar.cc"
            alt="avatar"
            className="cursor-pointer"
            onClick={() => navigate('/profile')}
          />
        </div>

        <Button
          type="text"
          icon={<MenuOutlined className="text-[#0f2ec4]" />}
          onClick={toggleMobileMenu}
          className="lg:hidden"
        />
      </div>

      <Drawer
        title="Pondy Ads"
        placement="right"
        onClose={toggleMobileMenu}
        open={isMobileMenuOpen}
        className="text-[#0f2ec4] font-poppins text-lg"
        width={250}
        extra={
          <Avatar
            src="https://i.pravatar.cc"
            alt="avatar"
            className="cursor-pointer"
            onClick={() => navigate('/profile')}
          />
        }
      >
        <Menu
          mode="vertical"
          selectedKeys={[selectedKey]}
          onClick={(item) => {
            handleMenuClick(item);
            toggleMobileMenu();
          }}
          className="border-none"
          style={{ border: 'none' }}
          items={[
            ...menuItems,
            {
              key: 'postAds',
              label: (
                <Button
                  type="primary"
                  className="w-full bg-gradient-to-r from-[#0f2ec4] to-[#0f2ec4]"
                  onClick={() => navigate('/postads')}
                >
                  {t('post-ads')}
                </Button>
              ),
            },
          ]}
        />
      </Drawer>
    </AntHeader>
  );
};

export default Header;
