import { Image, Popconfirm, Table, Tag, Button } from 'antd';
import { useEffect, useState } from 'react';
import { ApiUtils } from '../utils/api.jsx';
import { useTranslation } from 'react-i18next';
import UserSidebar from '../layouts/UserSidebar';
import PATHS from '../utils/constants/Paths.tsx';
import METHOD_TYPES from '../utils/constants/MethodTypes.tsx';
import Header from '../layouts/Header.jsx';
import DOMPurify from 'dompurify';
import Footer from '../layouts/Footer.jsx';
import '../tailwind.css';
import { MdDeleteOutline } from 'react-icons/md';
export default function AdminAdsPage() {
  const { t } = useTranslation();

  const [usersList, setUsersList] = useState([]);
  const [count, setCount] = useState([]);
  const [loading, setLoading] = useState(true);
  const [expandedRows, setExpandedRows] = useState({});

  useEffect(() => {
    fetchUsersAds();
  }, [location.search]);

  const fetchUsersAds = async () => {
    try {
      setLoading(true);
      const URL = `${PATHS.ADS.GET}`;
      const data = await ApiUtils(URL, '', METHOD_TYPES.GET);
      if (data.status === 200) {
        setUsersList(data.data.ads);
        setCount(data.data.count);
      }
      setLoading(false);
    } catch (error) {
      setLoading(false);
      console.log(error.message);
    }
  };

  const handleMarkAsApproved = async (id) => {
    try {
      const URL = `${PATHS.ADS.UPDATE}/${id}`;
      const data = await ApiUtils(URL, { status: 1 }, METHOD_TYPES.PUT);
      if (data.status !== 200) {
        console.log('error in update status post=====', data.message);
        return;
      } else {
        fetchUsersAds();
      }
    } catch (error) {
      console.log('error in status update post=====', error.message);
    }
  };
  const handleDeleteUser = async (id) => {
    try {
      const URL = `${PATHS.ADS.DELETE}/${id}`;
      console.log('delete>>>', URL);
      const data = await ApiUtils(URL, '', METHOD_TYPES.DELETE);
      if (data.status === 200) {
        fetchUsersAds();
      } else {
        console.log(data.message);
      }
    } catch (error) {
      console.log(error.message);
    }
  };

  const toggleShowMore = (key) => {
    setExpandedRows((prevState) => ({
      ...prevState,
      [key]: !prevState[key],
    }));
  };

  const renderDescription = (text, record) => {
    const sanitizedText = DOMPurify.sanitize(text);
    const wordLimit = 15;
    const words = sanitizedText.split(' ');

    if (words.length <= wordLimit) {
      return <div dangerouslySetInnerHTML={{ __html: sanitizedText }} />;
    }

    const truncatedText = words.slice(0, wordLimit).join(' ');

    return (
      <div>
        <div
          dangerouslySetInnerHTML={{
            __html: expandedRows[record.key]
              ? sanitizedText
              : truncatedText + '...',
          }}
        />
        <Button
          type="link"
          onClick={() => toggleShowMore(record.key)}
          style={{ padding: 0 }}
        >
          {expandedRows[record.key] ? t('show-less') : t('show-more')}
        </Button>
      </div>
    );
  };

  const columns = [
    {
      title: t('photo'),
      dataIndex: 'photo',
      key: 'photo',
      filteredValue: null,
      render: (text) => <Image width={50} src={text && text} />,
    },
    {
      title: t('category'),
      dataIndex: 'category',
      key: 'category',
      render: (text) =>
        text === 1 ? (
          <Tag color="green">{t('Sales')}</Tag>
        ) : text === 2 ? (
          <Tag color="geekblue">{t('Rent')}</Tag>
        ) : (
          <Tag color="purple">{t('Jobs')}</Tag>
        ),
    },
    {
      title: t('status'),
      dataIndex: 'status',
      key: 'status',
      render: (status, record) => (
        <>
          {status === 2 ? (
            <Popconfirm
              title={t('are-you-sure-you-want-to-approve-this-post')}
              onConfirm={() => handleMarkAsApproved(record._id)}
              okText={t('yes')}
              cancelText={t('no')}
            >
              <a
                href="#"
                className="font-medium text-red-500 hover:underline cursor-pointer"
              >
                {t('mark-as-approved')}
              </a>
            </Popconfirm>
          ) : status === 1 ? (
            <Tag color="green">{t('active')}</Tag>
          ) : status === 2 ? (
            <Tag color="geekblue">{t('inActive')}</Tag>
          ) : (
            <Tag color="purple">{t('deleted')}</Tag>
          )}
        </>
      ),
    },
    {
      title: t('title-0'),
      dataIndex: 'title',
      key: 'title',
    },
    {
      title: t('description'),
      dataIndex: 'descp',
      key: 'descp',
      render: renderDescription,
      className: 'description-column',
    },
    {
      title: t('delete-ads'),
      key: 'delete',
      render: (text, record) => (
        <Popconfirm
          title={t('are-you-delete-this')}
          onConfirm={() => handleDeleteUser(record._id)}
          okText={t('yes-im-sure')}
          cancelText={t('no-cancel')}
        >
          <span className="cursor-pointer">
            <MdDeleteOutline size={20} color="red" />
          </span>
        </Popconfirm>
      ),
    },
  ];

  return (
    <div className="bg-[#b7d9ff] h-screen">
      <Header />
      <div className="flex w-screen bg-[#b7d9ff] h-[90%] p-8 gap-8">
        <UserSidebar />
        <div className="lg:w-full table-auto overflow-x-scroll md:mx-auto  scrollbar scrollbar-track-slate-100 scrollbar-thumb-slate-300 dark:scrollbar-track-slate-700 dark:scrollbar-thumb-slate-500">
          <div className="px-5">
            Total Advertise count:
            <span className="font-bold text-blue-600 dark:text-yellow-400">
              {' '}
              {count}
            </span>
          </div>
          <Table
            dataSource={usersList.map((item, index) => ({
              ...item,
              key: item._id || index,
            }))}
            columns={columns}
            loading={loading}
            className="py-4 px-4 "
          />
        </div>
      </div>
      <Footer />
    </div>
  );
}
