import { Card, Button, Input, Form } from 'antd';
import Lottie from 'lottie-react';
import { SendOutlined } from '@ant-design/icons';
import animation from '.././assets/lottie-json/contact.json';
import Header from '../layouts/Header';
import { useNavigate } from 'react-router-dom';
import Footer from '../layouts/Footer.jsx';

const { TextArea } = Input;

const Contact = () => {
  const navigate = useNavigate();

  const onFinish = (values) => {
    console.log('Success:', values);
    // Handle form submission here
    navigate('/postads');
  };

  return (
    <div className="flex justify-center items-center flex-col h-screen w-full">
      <div className="w-full sticky top-0">
        <Header />
      </div>
      <div className="flex justify-center items-center w-full h-full bg-[#b7d9ff]">
        <Card className=" flex   gap-4">
          <div className="flex   justify-center items-center flex-row">
            <div className="w-1/2 h-full flex justify-center items-center">
              <Lottie
                animationData={animation}
                loop={false}
                className="w-full"
              />
            </div>
            <div className="flex justify-center items-center flex-col w-1/2 gap-2">
              <div className="text-3xl font-semibold w-full  text-[#0f2ec4]">
                Contact US
                <div className="font-normal text-base  text-black">
                  Reach Out and well get in touch with you soon.
                </div>
              </div>
              <Form
                name="contact"
                onFinish={onFinish}
                className="flex justify-center items-center flex-col gap-4 w-full  py-5"
              >
                <Form.Item
                  name="name"
                  rules={[
                    { required: true, message: 'Please input your name!' },
                  ]}
                  className="w-full"
                >
                  <Input
                    placeholder="Name"
                    style={{ borderColor: '#0f2ec4' }}
                  />
                </Form.Item>
                <Form.Item
                  name="email"
                  rules={[
                    { required: true, message: 'Please input your email!' },
                    { type: 'email', message: 'Please enter a valid email!' },
                  ]}
                  className="w-full"
                >
                  <Input
                    placeholder="Email"
                    style={{ borderColor: '#0f2ec4' }}
                  />
                </Form.Item>
                <Form.Item
                  name="message"
                  rules={[
                    { required: true, message: 'Please input your message!' },
                  ]}
                  className="w-full"
                >
                  <TextArea
                    placeholder="Message"
                    rows={4}
                    style={{ borderColor: '#0f2ec4' }}
                  />
                </Form.Item>
                <Form.Item>
                  <Button
                    type="primary"
                    htmlType="submit"
                    icon={<SendOutlined />}
                    className="flex items-center gap-3 text-white font-poppins font-medium text-sm rounded-full bg-gradient-to-r from-[#0f2ec4] to-[#0f2ec4] py-2 px-4 normal-case transition hover:scale-105"
                  >
                    Submit
                  </Button>
                </Form.Item>
              </Form>
            </div>
          </div>
        </Card>
      </div>
      <div className="w-full">
        <Footer />
      </div>
    </div>
  );
};

export default Contact;
