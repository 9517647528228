import { Image, Table, Tag } from 'antd';
import { useEffect, useState } from 'react';
import { ApiUtils } from '../utils/api.jsx';
import { useTranslation } from 'react-i18next';
import UserSidebar from '../layouts/UserSidebar';
import PATHS from '../utils/constants/Paths.tsx';
import useAuthUser from 'react-auth-kit/hooks/useAuthUser';
import METHOD_TYPES from '../utils/constants/MethodTypes.tsx';
import Header from '../layouts/Header.jsx';
import DOMPurify from 'dompurify';
import Footer from '../layouts/Footer.jsx';
import loadingIcon from '.././assets/lottie-json/logo Animation.json';
import Lottie from 'lottie-react';

export default function UserAds() {
  const authUser = useAuthUser();
  const userDetails = authUser.userDetails;
  const { t } = useTranslation();

  const [usersList, setUsersList] = useState([]);
  const [count, setCount] = useState([]);
  const [loading, setLoading] = useState(true);
  const [isLoading, setIsLoading] = useState(false);

  useEffect(() => {
    fetchUsersAds();
  }, [location.search]);

  const fetchUsersAds = async () => {
    try {
      setLoading(true);
      setIsLoading(true);
      const URL = `${PATHS.ADS.GET}?username=${userDetails?.username}`;
      const data = await ApiUtils(URL, '', METHOD_TYPES.GET);
      if (data.status === 200) {
        setUsersList(data.data.ads);
        setCount(data.data.count);
      }
      setLoading(false);
      setIsLoading(false);
    } catch (error) {
      setLoading(false);
      setIsLoading(false);
      console.log(error.message);
    }
  };
  console.log('user>list>>>>>>', usersList);
  const columns = [
    {
      title: t('photo'),
      dataIndex: 'photo',
      key: 'photo',
      filteredValue: null,
      render: (text) => <Image width={50} src={text && text} />,
    },
    {
      title: t('category'),
      dataIndex: 'category',
      key: 'category',
      render: (text) =>
        text === 1 ? (
          <Tag color="green">
            {/* eslint-disable-next-line react/no-unescaped-entities */}
            {t('sales')}
          </Tag>
        ) : text === 2 ? (
          <Tag color="geekblue">
            {/* eslint-disable-next-line react/no-unescaped-entities */}
            {t('rents')}
          </Tag>
        ) : (
          <Tag color="purple">
            {' '}
            {/* eslint-disable-next-line react/no-unescaped-entities */}
            {t('jobs')}
          </Tag>
        ),
    },
    {
      title: t('status-0'),
      dataIndex: 'status',
      key: 'status',
      render: (text) =>
        text === 1 ? (
          <Tag color="green">{t('active')}</Tag>
        ) : text === 2 ? (
          <Tag color="blue">{t('yet-to-approve')}</Tag>
        ) : (
          <Tag color="red">{t('deleted')}</Tag>
        ),
    },
    {
      title: t('title-0'),
      dataIndex: 'title',
      key: 'title',
    },
    {
      title: t('description'),
      dataIndex: 'descp',
      key: 'descp',
      render: (text) => (
        <div
          dangerouslySetInnerHTML={{ __html: DOMPurify.sanitize(text) }} // Sanitize the HTML for security
        />
      ),
    },
  ];

  return (
    <div className="bg-[#b7d9ff] h-screen">
      <Header />
      <div className="flex w-full bg-[#b7d9ff] h-[90%] p-8 gap-8">
        <UserSidebar />
        <>
          <div className="lg:w-full h-full table-auto overflow-x-scroll md:mx-auto p-3 scrollbar scrollbar-track-slate-100 scrollbar-thumb-slate-300 dark:scrollbar-track-slate-700 dark:scrollbar-thumb-slate-500">
            <div>
              {t('total-advertise-count')}
              <span className="font-bold text-blue-600 dark:text-yellow-400">
                {' '}
                {count}
              </span>
            </div>
            <Table
              dataSource={usersList.map((item, index) => ({
                ...item,
                key: item._id || index,
              }))}
              columns={columns}
              loading={loading}
              className="py-4 rounded-lg "
            />
          </div>
        </>
      </div>
      {isLoading && (
        <div className="absolute w-screen h-screen flex justify-center items-center inset-0 bg-white bg-opacity-50">
          <Lottie animationData={loadingIcon} loop={true} className="h-40" />
        </div>
      )}
      <Footer />
    </div>
  );
}
