import { StrictMode } from 'react';
import { createRoot } from 'react-dom/client';
import App from './App';
import './tailwind.css';
import { GoogleOAuthProvider } from '@react-oauth/google';
import { store, persistor } from './redux/store.js';
import { Provider } from 'react-redux';
import { PersistGate } from 'redux-persist/integration/react';
import React from 'react';
import * as Sentry from '@sentry/react';
import { LanguageContextProvider } from './utils/context/LanguageContext.tsx';

Sentry.init({
  dsn: import.meta.env.VITE_SENTRY_DSN,
  integrations: [
    Sentry.browserTracingIntegration(),
    Sentry.metrics.metricsAggregatorIntegration(),
    Sentry.reactRouterV6BrowserTracingIntegration({
      useEffect: React.useEffect,
    }),
    Sentry.replayIntegration({
      maskAllText: false,
      blockAllMedia: false,
    }),
  ],
  environment: import.meta.env.VITE_SENTRY_ENV,
  tracesSampleRate: 1.0,
  tracePropagationTargets: ['localhost', /^https:\/\/yourserver\.io\/api/],
  replaysSessionSampleRate: 0.1,
  replaysOnErrorSampleRate: 1.0,
});

createRoot(document.getElementById('root')).render(
  <PersistGate persistor={persistor}>
    <Provider store={store}>
      <StrictMode>
        <GoogleOAuthProvider clientId="787954308593-52flq9gjuv885ul8phs61kofn08j9a4i.apps.googleusercontent.com">
          <LanguageContextProvider>
            <App />
          </LanguageContextProvider>
        </GoogleOAuthProvider>
      </StrictMode>
    </Provider>
  </PersistGate>,
);
