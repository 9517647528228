import { Message, Button } from '../../utils/antd-components/index.tsx';
import { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import Lottie from 'lottie-react';
import animation from '../../assets/lottie-json/SignIn Animation.json';
import { RxEnter } from 'react-icons/rx';
import { useDispatch, useSelector } from 'react-redux';
import {
  signInStart,
  signInPending,
  signInFailure,
  SignInRiderect,
} from '../../redux/user/userSlice.js';
import { useTranslation } from 'react-i18next';
import useSignIn from 'react-auth-kit/hooks/useSignIn';
import { ApiUtils } from '../../utils/api.jsx';
import PATHS from '../../utils/constants/Paths.tsx';
import METHOD_TYPES from '../../utils/constants/MethodTypes.tsx';
import routesnkt from '../../routes/routes.json';
import { Typography, Input, Form, message } from 'antd';
import { Link } from 'react-router-dom';
import useAuthUser from 'react-auth-kit/hooks/useAuthUser';

export default function SignIn() {
  const signIn = useSignIn();
  const { t } = useTranslation();
  const [loading, setLoading] = useState(false);
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const {
    error: errorMessage,
    success: successMessage,
    redirect,
  } = useSelector((state) => state.user);
  const authUser = useAuthUser();
  const userDetails = authUser ? authUser.userDetails : null;

  useEffect(() => {
    window.scrollTo(0, 0);
    dispatch(signInPending(''));
    dispatch(signInFailure(''));
    if (userDetails) {
      message.success(t('youre-are-logged-in-0'));
      navigate('/');
    } else if (userDetails === null) {
      navigate('/sign-in');
    }
  }, [userDetails, navigate, dispatch]);

  const handleSubmit = async (values) => {
    setLoading(true);
    console.log('values', values);
    try {
      dispatch(signInStart());
      const URL = PATHS.AUTH.SIGNIN;
      const data = await ApiUtils(URL, values, METHOD_TYPES.POST);
      dispatch(signInPending(data));

      if (data.status !== 200 && data.status !== 401) {
        dispatch(signInFailure(data.message));
        setLoading(false);
      } else if (data.status === 401) {
        navigate(routesnkt.userVerify);
        setLoading(false);
      }

      if (data.status === 200) {
        if (
          signIn({
            auth: {
              token: data.access_token,
              type: 'Bearer',
            },
            userState: {
              name: data.user.username,
              _id: data.user._id,
              userDetails: data.user,
              email: data.user.email,
              roles: data.user.roleId._id,
            },
          })
        ) {
          if (redirect) {
            let redirects = redirect;
            dispatch(SignInRiderect(''));
            navigate(redirects);
          } else {
            navigate(routesnkt.home);
          }
        } else {
          dispatch(signInFailure(t('error-something-went-wrong')));
        }
      }
      console.log('data====', data);
      setLoading(false);
    } catch (error) {
      setLoading(false);
      dispatch(signInFailure(error.message));
    }
  };

  const onFinishFailed = (errorInfo) => {
    console.log('Failed:', errorInfo);
  };

  return (
    <div className="flex justify-center items-center w-full h-screen overflow-hidden">
      <div className="w-full">
        <div className="flex flex-row justify-between">
          <div className="w-1/2 flex justify-center">
            <Lottie animationData={animation} loop={false} className="w-full" />
          </div>
          <div className="w-1/2 flex flex-col justify-center ">
            <Typography.Title
              level={1}
              style={{ color: '#0f2ec4' }}
              className="text-left font-poppins text-[#0f2ec4]"
            >
              {t('sign-in-0')}
            </Typography.Title>

            <Form
              name="basic"
              labelCol={{ span: 8 }}
              wrapperCol={{ span: 16 }}
              style={{ maxWidth: 600 }}
              initialValues={{ remember: true }}
              onFinish={handleSubmit}
              onFinishFailed={onFinishFailed}
              autoComplete="off"
              layout="vertical"
              className="flex flex-col px-4"
            >
              <Form.Item
                label={t('username-2')}
                name="username"
                rules={[
                  { required: true, message: t('please-input-your-username') },
                  {
                    min: 6,
                    message: t('username-must-be-at-least-6-characters-long'),
                  },
                ]}
              >
                <Input
                  placeholder={t('username-2')}
                  style={{ borderColor: '#0f2ec4' }}
                />
              </Form.Item>

              <Form.Item
                label={t('password-2')}
                name="password"
                rules={[
                  { required: true, message: t('please-input-your-password') },
                  {
                    pattern:
                      // eslint-disable-next-line no-useless-escape
                      /^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[!@#$%^&*()_+\-=\[\]{};':"\\|,.<>\/?]).{8,30}$/,
                    message: t(
                      'password-must-include-uppercase-lowercase-number-and-special-character',
                    ),
                  },
                ]}
                className="mb-0"
              >
                <Input.Password
                  placeholder={t('password-1')}
                  style={{ borderColor: '#0f2ec4' }}
                />
              </Form.Item>

              <Form.Item>
                <Button
                  type="primary"
                  htmlType="submit"
                  loading={loading}
                  className="w-full mt-5"
                  style={{ background: '#0f2ec4' }}
                >
                  <RxEnter className="w-6 h-6" />
                  <p className="font-semibold">{t('sign-in-0')}</p>
                </Button>
              </Form.Item>
              <Link to={routesnkt.signUp} className="w-9/12">
                <Typography className=" justify-center py-2 text-center flex gap-1">
                  {/* eslint-disable-next-line react/no-unescaped-entities */}
                  {t('dont-have-an-account')}
                  <button className="font-medium text-[#0f2ec4] hover:underline">
                    {t('sign-up-1')}
                  </button>
                </Typography>
              </Link>
              <p
                className="text-center text-blue-900 font-poppins hover:underline text-xs cursor-pointer w-9/12"
                onClick={() => navigate('/forgot-password')}
              >
                {t('forgot-password-click-here')}
              </p>
            </Form>
          </div>
        </div>
      </div>

      {errorMessage && (
        <Message className="mt-5" type="error" successMessage={errorMessage} />
      )}
      {successMessage && (
        <Message successMessage={successMessage} type="success" />
      )}
    </div>
  );
}
